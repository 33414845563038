export const localData = {
  groups: {
    data: [
      {
        id: '109',
        attributes: {
          code: 'admin',
          label: 'Administrația',
          locations: {
            data: [
              {
                id: '1536',
                attributes: {
                  label: 'Director',
                  code: 'director',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1537',
                attributes: {
                  label: 'Director adjunct pentru educație - Nadejda Gabură',
                  code: 'gabura',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1538',
                attributes: {
                  label: 'Director adjunct activitate didactică - Liuba Obada',
                  code: 'obada',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1539',
                attributes: {
                  label: 'Anticamera',
                  code: 'anti_camera',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
            ],
            __typename: 'LocationRelationResponseCollection',
          },
          __typename: 'Group',
        },
        __typename: 'GroupEntity',
      },
      {
        id: '110',
        attributes: {
          code: 'classrooms_floor_1',
          label: 'Cabinete etajul 1',
          locations: {
            data: [
              {
                id: '1540',
                attributes: {
                  label: 'Laboratorul de informatică 1 - 102',
                  code: 'c102',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1541',
                attributes: {
                  label: 'Laboratorul de informatică 2 - 103',
                  code: 'c103',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1542',
                attributes: {
                  label: 'Laboratorul de informatică 3 - 104',
                  code: 'c104',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1543',
                attributes: {
                  label: 'Laboratorul de informatică 4 - 105',
                  code: 'c105',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1544',
                attributes: {
                  label: 'Laboratorul de informatică 5 - 106',
                  code: 'c106',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1545',
                attributes: {
                  label: 'Secția Formare continuă - 107',
                  code: 'c107',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1546',
                attributes: {
                  label: 'Laboratorul de informatică 6 - 108',
                  code: 'c108',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1547',
                attributes: {
                  label: 'Catedra Limba și literatura româna - 109',
                  code: 'c109',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1548',
                attributes: {
                  label: 'Catedra Matematică - 110',
                  code: 'c110',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1549',
                attributes: {
                  label: 'Catedra Istorie și științe socioumane - 111',
                  code: 'c111',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1550',
                attributes: {
                  label: 'Biologie - 112',
                  code: 'c112',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1551',
                attributes: {
                  label: 'Firma de exercițiu - 113',
                  code: 'c113',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1552',
                attributes: {
                  label: 'Matematică - 114',
                  code: 'c114',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1553',
                attributes: {
                  label: 'Comitetul sindical al elevilor - 115',
                  code: 'c115',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1554',
                attributes: {
                  label: 'Matematică - 116',
                  code: 'c116',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1555',
                attributes: {
                  label: 'Istorie - 117',
                  code: 'c117',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1556',
                attributes: {
                  label: 'Limba engleză - 118',
                  code: 'c118',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1557',
                attributes: {
                  label: 'Istorie - 119',
                  code: 'c119',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1558',
                attributes: {
                  label: 'Metodist Olga Jumbei - 120',
                  code: 'c120',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1559',
                attributes: {
                  label: 'Limba și literatura română - 121',
                  code: 'c121',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1560',
                attributes: {
                  label: 'Matematică - 122',
                  code: 'c122',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1561',
                attributes: {
                  label: 'Matematică - 123',
                  code: 'c123',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1562',
                attributes: {
                  label: 'Matematică - 124',
                  code: 'c124',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1563',
                attributes: {
                  label: 'Sala de clasă - 125',
                  code: 'c125',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1564',
                attributes: {
                  label: 'Sala de clasă - 126',
                  code: 'c126',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1565',
                attributes: {
                  label: 'Limba franceză - 127',
                  code: 'c127',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1566',
                attributes: {
                  label: 'Sala de clasă - 128',
                  code: 'c128',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1567',
                attributes: {
                  label: 'Sala de clasă - 129',
                  code: 'c129',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1568',
                attributes: {
                  label: 'Sala de clasă - 130',
                  code: 'c130',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1569',
                attributes: {
                  label: 'Sala de clasă - 131',
                  code: 'c131',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1570',
                attributes: {
                  label: 'Sala de clasă - 132',
                  code: 'c132',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1571',
                attributes: {
                  label: 'Biologie / Chimie - 133',
                  code: 'c133',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1572',
                attributes: {
                  label: 'Fizică / Astronomie - 134',
                  code: 'c134',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1573',
                attributes: {
                  label: 'Fizică / Astronomie - 135',
                  code: 'c135',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1574',
                attributes: {
                  label:
                    'Secția Asigurarea calității (Șef: Lebediuc Adela) - 136',
                  code: 'c136',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1575',
                attributes: {
                  label: 'Fizică / Astronomie - 138',
                  code: 'c138',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1576',
                attributes: {
                  label: 'Sala de clasă - 139',
                  code: 'c139',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1577',
                attributes: {
                  label: 'Atelierul de lucru 1 - 140',
                  code: 'c140',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1578',
                attributes: {
                  label: 'Atelierul de lucru 2 - 141',
                  code: 'c141',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
            ],
            __typename: 'LocationRelationResponseCollection',
          },
          __typename: 'Group',
        },
        __typename: 'GroupEntity',
      },
      {
        id: '111',
        attributes: {
          code: 'classrooms_floor_2',
          label: 'Cabinete etajul 2',
          locations: {
            data: [
              {
                id: '1579',
                attributes: {
                  label: 'Formarea continuă și resurse educaționale - 202',
                  code: 'c202',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1580',
                attributes: {
                  label: 'Laboratorul de informatică 7 - 203',
                  code: 'c203',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1581',
                attributes: {
                  label: 'Laboratorul de informatică 8 - 204',
                  code: 'c204',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1582',
                attributes: {
                  label: 'Laboratorul de informatică 9 - 205',
                  code: 'c205',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1583',
                attributes: {
                  label: 'Inginer Roman Jalbă - 206',
                  code: 'c206',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1584',
                attributes: {
                  label: 'Laboratorul de informatică 10 - 207',
                  code: 'c207',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1585',
                attributes: {
                  label: 'Catedra Informatică 1 - 208',
                  code: 'c208',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1586',
                attributes: {
                  label: 'Laboratorul de informatică 11 - 209',
                  code: 'c209',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1587',
                attributes: {
                  label: 'Laboratorul de informatică 12 - 210',
                  code: 'c210',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1588',
                attributes: {
                  label: 'Informatică - 211',
                  code: 'c211',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1589',
                attributes: {
                  label:
                    'Director  adjunct instruire practică și producere Andrei Ciobanu - 212',
                  code: 'c212',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1590',
                attributes: {
                  label: 'Informatică - 213',
                  code: 'c213',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1591',
                attributes: {
                  label: 'Laboratorul de informatică 13 - 214',
                  code: 'c214',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1592',
                attributes: {
                  label: 'Catedra Informatică 2 - 215',
                  code: 'c215',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1593',
                attributes: {
                  label: 'Laboratorul de informatică 14 - 216',
                  code: 'c216',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1594',
                attributes: {
                  label: 'Informatică - 217',
                  code: 'c217',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1595',
                attributes: {
                  label: 'Secția didactică 2 (Șef: Irina Pasecinic) - 218',
                  code: 'c218',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1596',
                attributes: {
                  label: 'Istorie - 219',
                  code: 'c219',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1597',
                attributes: {
                  label: 'Limba franceză - 220',
                  code: 'c220',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1598',
                attributes: {
                  label: 'Servicii administrative - 221',
                  code: 'c221',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1599',
                attributes: {
                  label: 'Limba și literatura română - 222',
                  code: 'c222',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1600',
                attributes: {
                  label: 'Secția didactică 4 (Șef: Vasile Melnic) - 223',
                  code: 'c223',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1601',
                attributes: {
                  label: 'Limba și literatura română - 224',
                  code: 'c224',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1602',
                attributes: {
                  label: 'Servicii administrative - 225',
                  code: 'c225',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1603',
                attributes: {
                  label: 'Limba engleză - 226',
                  code: 'c226',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1604',
                attributes: {
                  label: 'Laboratorul echipamente de birotică - 227',
                  code: 'c227',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1605',
                attributes: {
                  label: 'Psiholog Viorica Osoianu - 228',
                  code: 'c228',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1606',
                attributes: {
                  label: 'Consiliul elevilor - 229',
                  code: 'c229',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1607',
                attributes: {
                  label: 'Sala de clasă - 230',
                  code: 'c230',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1608',
                attributes: {
                  label: 'Biologie - 231',
                  code: 'c231',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1609',
                attributes: {
                  label: 'Limba franceză - 232',
                  code: 'c232',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1610',
                attributes: {
                  label: 'Atelierul de lucru 3 - 233',
                  code: 'c233',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1611',
                attributes: {
                  label: 'Sala de clasă - 234',
                  code: 'c234',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1612',
                attributes: {
                  label: 'Limba engleză - 235',
                  code: 'c235',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1613',
                attributes: {
                  label: 'Sala de clasă - 236',
                  code: 'c236',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1614',
                attributes: {
                  label: 'Limba franceză - 237',
                  code: 'c237',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1615',
                attributes: {
                  label: 'Atelier inginer - 238',
                  code: 'c238',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1616',
                attributes: {
                  label: 'Catedra  Economie - 239',
                  code: 'c239',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1617',
                attributes: {
                  label: 'Analiza economico-financiară - 240',
                  code: 'c240',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1618',
                attributes: {
                  label: 'Contabilitate - 241',
                  code: 'c241',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1619',
                attributes: {
                  label: 'Științe economice - 242',
                  code: 'c242',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1620',
                attributes: {
                  label: 'Științe economice - 243',
                  code: 'c243',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1621',
                attributes: {
                  label: 'Științe economice - 244',
                  code: 'c244',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1622',
                attributes: {
                  label: 'Catedra Servicii administrative - 245',
                  code: 'c245',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1623',
                attributes: {
                  label: 'Sala de clasă - 246',
                  code: 'c246',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1624',
                attributes: {
                  label: 'Sala de clasă - 247',
                  code: 'c247',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1625',
                attributes: {
                  label: 'Secție didactică 3 (Șef: Ala Dumneanu) - 248',
                  code: 'c248',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1626',
                attributes: {
                  label: 'Sala de clasă - 249',
                  code: 'c249',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1627',
                attributes: {
                  label: 'Sala de clasă - 250',
                  code: 'c250',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1628',
                attributes: {
                  label: 'Sala de clasă - 251',
                  code: 'c251',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
            ],
            __typename: 'LocationRelationResponseCollection',
          },
          __typename: 'Group',
        },
        __typename: 'GroupEntity',
      },
      {
        id: '112',
        attributes: {
          code: 'classrooms_floor_3',
          label: 'Cabinete etajul 3',
          locations: {
            data: [
              {
                id: '1629',
                attributes: {
                  label: 'Laboratorul de informatică 16 - 310',
                  code: 'c310',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1630',
                attributes: {
                  label: 'Laboratorul de informatică 17 - 311',
                  code: 'c311',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1631',
                attributes: {
                  label: 'Laboratorul de informatică 18 - 312',
                  code: 'c312',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1632',
                attributes: {
                  label: 'Limba franceză - 313',
                  code: 'c313',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1633',
                attributes: {
                  label: 'Secția didactică 1 (Șef :Nicșan Adrian) - 314',
                  code: 'c314',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1634',
                attributes: {
                  label: 'Limba engleză - 315',
                  code: 'c315',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1635',
                attributes: {
                  label: 'Catedra Limbi străine - 316',
                  code: 'c316',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1636',
                attributes: {
                  label: 'Limba și literatura română - 301',
                  code: 'c301',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1637',
                attributes: {
                  label: 'Limba engleză - 302',
                  code: 'c302',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1638',
                attributes: {
                  label: 'Geografie - 303',
                  code: 'c303',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1639',
                attributes: {
                  label: 'Limba și literatura română - 304',
                  code: 'c304',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1640',
                attributes: {
                  label: 'Geografie - 305',
                  code: 'c305',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1641',
                attributes: {
                  label: 'Chimie - 306',
                  code: 'c306',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1642',
                attributes: {
                  label: 'Laboratorul de chimie - 307',
                  code: 'c307',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1643',
                attributes: {
                  label: 'Catedra Științe reale - 308',
                  code: 'c308',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1644',
                attributes: {
                  label: 'Limba engleză - 309',
                  code: 'c309',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
            ],
            __typename: 'LocationRelationResponseCollection',
          },
          __typename: 'Group',
        },
        __typename: 'GroupEntity',
      },
      {
        id: '113',
        attributes: {
          code: 'uncategorized',
          label: 'Altele',
          locations: {
            data: [
              {
                id: '1523',
                attributes: {
                  label: 'Intrare centrală',
                  code: 'intrare_principala',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1524',
                attributes: {
                  label: 'Casa',
                  code: 'casa',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1525',
                attributes: {
                  label: 'Contabilitate',
                  code: 'contabilitate',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1526',
                attributes: {
                  label: 'Xerox',
                  code: 'xerox',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1527',
                attributes: {
                  label: 'Bufet',
                  code: 'bufet',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1528',
                attributes: {
                  label: 'Sala de festivități - 201',
                  code: 'c201',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1529',
                attributes: {
                  label: 'Sala de lectură',
                  code: 'sala_lectura',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1530',
                attributes: {
                  label: 'Sala de sport - 137',
                  code: 'sala_sportiva',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1531',
                attributes: {
                  label: 'Secția serviciu persoană',
                  code: 'sectia_serviciul_personal',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1532',
                attributes: {
                  label: 'Biblioteca Sala împrumut - 101',
                  code: 'c101',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1533',
                attributes: {
                  label: 'Poarta',
                  code: 'poarta',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1534',
                attributes: {
                  label: 'Intrare laterală 1',
                  code: 'intrare_laterala_1',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
              {
                id: '1535',
                attributes: {
                  label: 'Intrare laterală 2',
                  code: 'intrare_laterala_2',
                  __typename: 'Location',
                },
                __typename: 'LocationEntity',
              },
            ],
            __typename: 'LocationRelationResponseCollection',
          },
          __typename: 'Group',
        },
        __typename: 'GroupEntity',
      },
    ],
    __typename: 'GroupEntityResponseCollection',
  },
}
