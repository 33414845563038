export const makePolyline = (pointArr: string[], color: string, w: number) => `
  <polyline
    class="drawn-path-polyline"
    stroke-linejoin="round"
    stroke-linecap="round"
    stroke="${color}"
    stroke-width="${w}"
    fill="none"
    marker-start="url(#arrow)"
    marker-mid="url(#arrow)"
    marker-end="url(#arrow)"
    points="${pointArr.join(' ')}"
  ></polyline>
`

export const arrowMarkerDefs = `
<defs>
  <marker
    id="arrow"
    viewBox="0 0 83 64"
    markerWidth="0.75"
    markerHeight="0.75"
    orient="auto"
    refY="32"
    refX="41.5"
  >
    <path
      d="M9.89221 32L73.8922 32M73.8922 32L51.9635 53.9287M73.8922 32L51.9635 10.0713"
      stroke="#ffffff"
      stroke-width="18"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </marker>
</defs>
`

const circleImgSize = 32

export const makeCircleImg = (x: number, y: number, src: string) => `
<foreignObject
  x="${x - circleImgSize / 2}"
  y="${y - circleImgSize / 2}"
  width="${circleImgSize}"
  height="${circleImgSize}"
>
  <img
    width="${circleImgSize}"
    height="${circleImgSize}"
    src="${src}"
    alt=""
  />
</foreignObject>
`
