import { ErrorBoundary } from 'react-error-boundary'

import { ApolloProvider } from '@apollo/client'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

import { apolloClient } from './apollo-client'
import PageCrashFallback from './app/PageCrashFallback'
import IndoorMap from './app/indoor-map/IndoorMap'
import { localData } from './app/indoor-map/local-data'

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
})

export default function App() {
  return (
    <ErrorBoundary FallbackComponent={PageCrashFallback}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <IndoorMap query={localData as any} />
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
