import { baseValueOf, distance } from './utils'

namespace Graph {
  export interface IPoint {
    x: number
    y: number
  }

  export class Point {
    x: number
    y: number
    r: number
    id: string
    neighbours: Edge[]

    constructor($circle: SVGCircleElement) {
      this.x = baseValueOf($circle.cx)
      this.y = baseValueOf($circle.cy)
      this.r = baseValueOf($circle.r)
      this.id = $circle.id
      this.neighbours = []
    }
  }
  export class Edge {
    source: Point
    target: Point

    constructor(source: Point, target: Point) {
      this.source = source
      this.target = target
    }

    getLength() {
      return distance(this.source, this.target)
    }

    static connect(point1: Point, point2: Point) {
      if (point1 === point2) return
      if (point1.neighbours.find((edge) => edge.target === point2)) return
      if (point2.neighbours.find((edge) => edge.target === point1)) return
      point1.neighbours.push(new Edge(point1, point2))
      point2.neighbours.push(new Edge(point2, point1))
    }
  }

  export class Portal extends Edge {
    constructor(source: Point, target: Point) {
      super(source, target)
    }

    getLength(): number {
      return 0
    }

    static connect(point1: Point, point2: Point) {
      if (point1 === point2) return
      if (point1.neighbours.find((edge) => edge.target === point2)) return
      if (point2.neighbours.find((edge) => edge.target === point1)) return
      point1.neighbours.push(new Portal(point1, point2))
      point2.neighbours.push(new Portal(point2, point1))
    }
  }

  export class Path {
    #points: Point[]
    #length: number

    constructor(points: Point[]) {
      this.setPoints(points)
    }

    setPoints(points: Point[]) {
      this.#points = points
      let [prev, ...rest] = points
      this.#length = rest.reduce(
        (sum, point) => sum + distance(point, prev) || ((prev = point) as any),
        0
      )
    }

    getPoints() {
      return [...this.#points]
    }

    getLength() {
      return this.#length
    }
  }
}

export default Graph
