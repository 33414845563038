import { useEffect } from 'react'
import { zoom } from 'd3-zoom'
import { select } from 'd3-selection'
import { styled } from '@mui/material'

const StyledFloorContainer = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  .floor-zoom-container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
  }
  .floor-path-container {
    position: relative;
    width: 100%;
  }
  .path {
    position: relative;
    opacity: 0;
    width: 100%;

    svg {
      width: 100%;
      display: block;
    }
  }

  .drawn-path {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      display: block;
    }
    width: 100%;
  }

  .img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
`

export interface FloorContainerProps {
  mapSVG: string
  mapImgSrc: string
  drawnPathSVG: string
  mainSvgRef: React.MutableRefObject<Element>
  onOverlayRectClick: EventListener
}

const FloorContainer: React.FC<FloorContainerProps> = ({
  mapSVG,
  mapImgSrc,
  drawnPathSVG,
  mainSvgRef,
  onOverlayRectClick,
}) => {
  useEffect(() => {
    const zoomListener = select<Element, unknown>('#zoom-listener')
    const selection = select<Element, unknown>('#zoom-container')

    const svgSelection = select('#zoom-container svg')
    const w: SVGAnimatedLength = svgSelection.property('width')
    const h: SVGAnimatedLength = svgSelection.property('height')

    svgSelection
      // In production build the viewBox is removed by the svg transformer
      .attr('viewBox', `0 0 ${w.baseVal.value} ${h.baseVal.value}`)
      .attr('height', undefined)
      .attr('width', undefined)

    const mainSvgContainer = select('#main-svg-container svg')
    mainSvgRef.current = mainSvgContainer.node() as Element

    zoomListener.call(
      zoom()
        .scaleExtent([0.5, 40])
        .on('zoom', ({ transform }) => {
          selection.style(
            'transform',
            'translate(' +
              transform.x +
              'px,' +
              transform.y +
              'px) scale(' +
              transform.k +
              ')'
          )
          selection.style('transform-origin', '0 0')
        })
    )

    // Two groups because it's a component
    document.querySelectorAll('#Overlays > g > g').forEach((c) =>
      c.addEventListener('click', (e) => {
        onOverlayRectClick(e)
      })
    )
  }, [mapSVG, mapImgSrc])

  return (
    <StyledFloorContainer id="zoom-listener">
      <div className="floor-zoom-container" id="zoom-container">
        <div className="floor-path-container">
          <img className="img" src={mapImgSrc} alt="" />
          <div
            id="main-svg-container"
            className="path"
            dangerouslySetInnerHTML={{ __html: mapSVG }}
          />
          <div
            className="drawn-path"
            dangerouslySetInnerHTML={{ __html: drawnPathSVG }}
          />
        </div>
      </div>
    </StyledFloorContainer>
  )
}

export default FloorContainer
