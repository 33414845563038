import { useMemo } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Drawer,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  styled,
  TextField,
  Typography,
  accordionSummaryClasses,
  Autocomplete,
  ListItemIcon,
  Paper,
} from '@mui/material'
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  GpsFixed as GpsFixedIcon,
  Repeat as RepeatIcon,
  Wc as WcIcon,
} from '@mui/icons-material'

import { grey } from '@mui/material/colors'

import { GetLocationsGroupsQuery } from '../../__generated__/graphql'

import parseLocationGroupsQuery from './parse-locations-groups-query'
import { LocationMeta } from './types'

export interface NavDrawerProps {
  open: boolean
  onClose: () => void
  onLocationSelect: (id: string) => void
  source: string
  target: string
  onSourceSelect: (id: string | undefined) => void
  onTargetSelect: (id: string | undefined) => void
  onWCSelect: () => void
  query: GetLocationsGroupsQuery
}

const Circle = styled('div')`
  background-color: gray;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
`
const BigCircle = styled(Circle)`
  width: 0.5rem;
  height: 0.5rem;
`
const PrimaryCircle = styled(BigCircle)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`
const StyledMenuItem = styled(MenuItem)`
  white-space: initial;
  &:not(:last-child) {
    border-bottom: 1px solid ${grey[300]};
  }
`
const StyledAccordion = styled(Accordion)`
  .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded} {
    border-bottom: 1px dashed ${grey[500]};
  }
`

export default function NavDrawer({
  open,
  onClose,
  onLocationSelect,
  source,
  target,
  onSourceSelect,
  onTargetSelect,
  onWCSelect,
  query,
}: NavDrawerProps) {
  const { groups, autoCompleteOptions } = useMemo(
    () => parseLocationGroupsQuery(query),
    [query]
  )

  const groupBy = (option: LocationMeta) => option.group

  const sourceOption = useMemo(
    () =>
      autoCompleteOptions.find((location) => location.code === source) || null,
    [source]
  )
  const targetOption = useMemo(
    (): LocationMeta | null =>
      target === 'wc'
        ? {
            id: '',
            code: target,
            group: '',
            label: 'WC',
          }
        : autoCompleteOptions.find((location) => location.code === target) ||
          null,
    [target]
  )

  const reverseValues = () => {
    onSourceSelect(target)
    onTargetSelect(source)
  }

  const clearValues = () => {
    onSourceSelect(undefined)
    onTargetSelect(undefined)
  }

  const handleLocationSelect = (id: string) => {
    onLocationSelect(id)
    if ((source && !target) || (!source && target)) onClose()
  }
  const handleSourceSelect = (id: string | undefined) => {
    onSourceSelect(id)
    if (id && target) onClose()
  }
  const handleTargetSelect = (id: string | undefined) => {
    onTargetSelect(id)
    if (id && source) onClose()
  }
  const handleWCSelect = () => {
    if (!source) return
    onWCSelect()
    onClose()
  }

  const isSelected = (code: string) => [source, target].includes(code)

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={{
          padding: '1rem',
          width: '420px',
          maxWidth: '100vw',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            mb: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.35rem',
            }}
          >
            <PrimaryCircle />
            <Circle />
            <Circle />
            <Circle />
            <Circle />
            <GpsFixedIcon color="secondary" fontSize="small" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.25rem',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Autocomplete
              value={sourceOption}
              onChange={(_, value) => {
                if (!value) handleSourceSelect(undefined)
                if (value instanceof Object) handleSourceSelect(value.code)
              }}
              disablePortal
              options={autoCompleteOptions}
              groupBy={groupBy}
              renderInput={(params) => (
                <TextField {...params} label="Locația mea" size="small" />
              )}
            />
            <Autocomplete
              value={targetOption}
              onChange={(_, value) => {
                if (!value) handleTargetSelect(undefined)
                if (value instanceof Object) handleTargetSelect(value.code)
              }}
              disablePortal
              options={autoCompleteOptions}
              groupBy={groupBy}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unde vreau să ajung"
                  size="small"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <IconButton disabled={!source && !target} onClick={reverseValues}>
              <RepeatIcon sx={{ transform: 'rotate(90deg)' }} />
            </IconButton>
            <IconButton disabled={!source && !target} onClick={clearValues}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Paper sx={{ mb: 4 }}>
          <MenuList>
            <StyledMenuItem
              selected={target === 'wc'}
              onClick={() => handleWCSelect()}
              disabled={!source}
            >
              <ListItemIcon>
                <WcIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Cel mai apropiat WC</Typography>
                {!source && (
                  <Typography fontSize="0.8rem">
                    Alege mai întâi unde te afli
                  </Typography>
                )}
              </ListItemText>
            </StyledMenuItem>
          </MenuList>
        </Paper>

        <Divider>
          <Typography variant="h6" sx={{ mb: 2 }} align="center">
            Alege unde{' '}
            {!source || (source && target) ? 'te afli' : 'vrei să ajungi'}
          </Typography>
        </Divider>

        <div>
          {groups.map((group) =>
            group.code !== 'uncategorized' ? (
              <StyledAccordion key={group.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={500} variant="subtitle1">
                    {group.label}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <MenuList>
                    {group.locations.map((location) => (
                      <StyledMenuItem
                        selected={isSelected(location.code)}
                        onClick={() => handleLocationSelect(location.code)}
                        key={location.id}
                      >
                        <ListItemText>{location.label}</ListItemText>
                      </StyledMenuItem>
                    ))}
                  </MenuList>
                </AccordionDetails>
              </StyledAccordion>
            ) : (
              <Card key={group.id} sx={{ my: 2 }}>
                <MenuList>
                  {group.locations.map((location) => (
                    <StyledMenuItem
                      selected={isSelected(location.code)}
                      onClick={() => handleLocationSelect(location.code)}
                      key={location.id}
                    >
                      <ListItemText>{location.label}</ListItemText>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </Card>
            )
          )}
        </div>
      </Box>
    </Drawer>
  )
}
