import Graph from './graph'
import { baseValueOf, findPoint } from './utils'

export function constructGraphFromNodes(nodes: Node[]) {
  const $circles = nodes.filter(
    (node) => node instanceof SVGCircleElement
  ) as SVGCircleElement[]
  const $lines = nodes.filter(
    (node) => node instanceof SVGLineElement
  ) as SVGLineElement[]
  const $paths = nodes.filter(
    (node) => node instanceof SVGPathElement
  ) as SVGPathElement[]

  const points: Graph.Point[] = $circles.map(
    ($circle) => new Graph.Point($circle)
  )

  function connectEdges(x1: number, y1: number, x2: number, y2: number) {
    const point1 = findPoint(points, { x: x1, y: y1 }),
      point2 = findPoint(points, { x: x2, y: y2 })

    if (!point1 || !point2) return

    Graph.Edge.connect(point1, point2)
  }

  $lines.forEach(($line) => {
    const x1 = baseValueOf($line.x1),
      y1 = baseValueOf($line.y1),
      x2 = baseValueOf($line.x2),
      y2 = baseValueOf($line.y2)

    connectEdges(x1, y1, x2, y2)
  })

  /**
   * TODO: One path extending over more points
   */
  $paths.forEach(($path) => {
    const p1 = $path.getPointAtLength(0)
    const p2 = $path.getPointAtLength($path.getTotalLength())

    const x1 = p1.x,
      y1 = p1.y,
      x2 = p2.x,
      y2 = p2.y

    connectEdges(x1, y1, x2, y2)
  })

  return { points }
}
