import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from '@apollo/client'

export const apolloClient = new ApolloClient({
  uri: process.env.CMS_GRAPHQL_URI,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${process.env.CMS_AUTH_TOKEN}`,
  },
})
