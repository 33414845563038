import Graph from './graph'
import { baseValueOf, findPoint } from './utils'

export interface PortalMeta {
  id: string
  targetId: string
  point: Graph.Point
}

export function getPortalMeta(
  portalNodes: Node[],
  points: Graph.Point[]
): PortalMeta[] {
  const $portals = portalNodes.filter(
    (node) => node instanceof SVGCircleElement
  ) as SVGCircleElement[]
  const result = $portals.map(($portal) => {
    const id = $portal.getAttribute('id')

    const regexp = /Portal\s*(\w+)\s*-\s*(\w+)/
    const matches = id.match(regexp)

    return {
      id: matches[1],
      targetId: matches[2],
      point: findPoint(points, {
        x: baseValueOf($portal.cx),
        y: baseValueOf($portal.cy),
      }),
    }
  })
  return result
}
