export function midMarkersPolyline(poly: SVGPolylineElement, spacing: number) {
  let svg = poly.ownerSVGElement
  for (let pts = poly.points, i = 1; i < pts.numberOfItems; ++i) {
    let p0 = pts.getItem(i - 1),
      p1 = pts.getItem(i)
    let dx = p1.x - p0.x,
      dy = p1.y - p0.y
    let d = Math.sqrt(dx * dx + dy * dy)
    let numPoints = Math.floor(d / spacing)
    dx /= numPoints
    dy /= numPoints
    for (let j = numPoints - 1; j > 0; --j) {
      let pt = svg.createSVGPoint()
      pt.x = p0.x + dx * j
      pt.y = p0.y + dy * j
      pts.insertItemBefore(pt, i)
    }
    if (numPoints > 0) i += numPoints - 1
  }
}
