import Graph from './graph'

export function flatChildNodes(root: Node): ChildNode[] {
  return Array.from(root.childNodes).flatMap((node) => [
    node,
    ...flatChildNodes(node),
  ])
}

export function findPoint(allPoints: Graph.Point[], included: Graph.IPoint) {
  return allPoints.find((point) => distance(point, included) <= point.r)
}

export function distance(point1: Graph.IPoint, point2: Graph.IPoint) {
  return Math.hypot(
    Math.abs(point1.x - point2.x),
    Math.abs(point1.y - point2.y)
  )
}

export function baseValueOf(animatedLength: SVGAnimatedLength) {
  return animatedLength.baseVal.value
}

export function createElementFrom(str: string) {
  const template = document.createElement('template')
  str = str.trim()
  template.innerHTML = str
  return template.content.firstChild!
}

export function randomColor() {
  return `#${Math.floor(Math.random() * (1 << (4 * 6)))
    .toString(16)
    .padStart(6, '0')}`
}
