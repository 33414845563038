import Graph from '../../graph/graph'

export default function filterForbiddenPaths(
  paths: Graph.Path[],
  forbiddenPairs: readonly (readonly [string, string])[]
) {
  return paths.filter((path) => {
    const points = path.getPoints()
    return !forbiddenPairs.some(
      (pair) =>
        points.find((p) => p.id === pair[0]) &&
        points.find((p) => p.id === pair[1])
    )
  })
}
