import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  ClickAwayListener,
  Fab,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import {
  NearMe as NearMeIcon,
  ArrowRightAlt as ArrowRightAltIcon,
} from '@mui/icons-material'

import { useLocation } from 'react-use'

import Floor1SVG from 'bundle-text:./svg-paths/floor1.svg'
import Floor2SVG from 'bundle-text:./svg-paths/floor2.svg'
import Floor3SVG from 'bundle-text:./svg-paths/floor3.svg'

import floor1BGSrc from './backgrounds/floor1.webp'
import floor2BGSrc from './backgrounds/floor2.webp'
import floor3BGSrc from './backgrounds/floor3.webp'

import { graphFromXMLText } from '../../graph/graph-from-xml-text'
import { connectGraphsWithPortals } from '../../graph/connect-graphs-with-portals'
import { findAllPaths } from '../../graph/all-paths'
import Graph from '../../graph/graph'
import { highlightPath } from '../../graph/highlight-path'
import { midMarkersPolyline } from '../../graph/mid-markers-polyline'

import { GetLocationsGroupsQuery } from '../../__generated__/graphql'

import FloorContainer from './FloorContainer'
import NavDrawer from './NavDrawer'
import filterForbiddenPaths from './filter-forbidden-paths'
import forbiddenPairs from './forbidden-pairs'

enum FloorCode {
  floor1 = '1',
  floor2 = '2',
  floor3 = '3',
}

const floorMapping = {
  [FloorCode.floor1]: {
    code: FloorCode.floor1,
    label: 'Etajul 1',
    thumbnail: '1',
    imgSrc: floor1BGSrc,
    mapSVG: Floor1SVG,
  },
  [FloorCode.floor2]: {
    code: FloorCode.floor2,
    label: 'Etajul 2',
    thumbnail: '2',
    imgSrc: floor2BGSrc,
    mapSVG: Floor2SVG,
  },
  [FloorCode.floor3]: {
    code: FloorCode.floor3,
    label: 'Etajul 3',
    thumbnail: '3',
    imgSrc: floor3BGSrc,
    mapSVG: Floor3SVG,
  },
}

export const DRAWN_PATHS_COUNT = 3

interface IndoorMapProps {
  query: GetLocationsGroupsQuery
}

export default function IndoorMap({ query }: IndoorMapProps) {
  const [open, setOpen] = useState(false)

  const location = useLocation()

  const drawerOpen = location.hash === '#nav'
  const handleDrawerOpen = () => (window.location.hash = 'nav')
  const handleDrawerClose = () => (window.location.hash = '')
  const [selectedFloor, setSelectedFloor] = useState(FloorCode.floor1)

  const [source, setSource] = useState<string>()
  const [target, setTarget] = useState<string>()
  const [paths, setPaths] = useState<Graph.Path[]>()

  const sourceRef = useRef(source)
  const targetRef = useRef(target)
  sourceRef.current = source
  targetRef.current = target

  console.log('source', source)
  console.log('target', target)

  const svgRef = useRef<SVGSVGElement>()

  const anchorRef = useRef(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleSelect = (floorCode: FloorCode, event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setSelectedFloor(floorCode)
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const { pointsFloor, allPoints } = useMemo(() => {
    const graphs = {
      1: graphFromXMLText(Floor1SVG),
      2: graphFromXMLText(Floor2SVG),
      3: graphFromXMLText(Floor3SVG),
    }

    console.log('graphs', graphs)

    connectGraphsWithPortals(graphs)

    const pointsFloor = new Map(
      Object.entries(graphs).flatMap(([floor, g]) =>
        g.points.map((p) => [p, floor])
      )
    )

    const allPoints = Object.values(graphs)
      .map((g) => g.points)
      .flat()

    return { graphs, allPoints, pointsFloor }
  }, [])

  useEffect(() => {
    if (!source || !target) return

    if (target === 'wc') {
      const sourcePoint = allPoints.find((point) => point.id === source)

      if (!sourcePoint) return

      let paths = findAllPaths(sourcePoint, (point) =>
        point.id.startsWith('wc')
      )

      paths = paths.sort((a, b) => a.getLength() - b.getLength())
      const shortestPath = paths[0]
      if (!shortestPath) return

      setPaths([shortestPath])
    } else {
      const sourcePoint = allPoints.find((point) => point.id === source)
      const targetPoint = allPoints.find((point) => point.id === target)

      if (!sourcePoint || !targetPoint) return

      let paths = findAllPaths(sourcePoint, targetPoint)
      paths = filterForbiddenPaths(paths, forbiddenPairs)
      paths = paths
        .sort((a, b) => a.getLength() - b.getLength())
        .slice(0, DRAWN_PATHS_COUNT)

      const k = 2
      // If the next path is more than k times longers it's a bad idea to show it
      while (paths.length > 1) {
        if (
          paths.length > 1 &&
          paths[paths.length - 1].getLength() > paths[0].getLength() * k
        )
          paths.pop()
        else break
      }

      setPaths(paths)
    }
  }, [source, target])

  useEffect(() => {
    if (source) {
      const startPoint = allPoints.find((point) => point.id === source)
      const floor = pointsFloor.get(startPoint) as FloorCode
      if (floor) setSelectedFloor(floor)
    }
  }, [source])

  const selectedFloorMeta = floorMapping[selectedFloor]

  const viewBox = (() => {
    if (!svgRef.current) return ''
    const { width, height } = svgRef.current.viewBox.baseVal
    return [0, 0, width, height].join(' ')
  })()

  const highlightedPath = useMemo(
    () =>
      highlightPath(
        paths?.map((p) => p.getPoints()) || [],
        pointsFloor,
        selectedFloor
      ),
    [pointsFloor, selectedFloor, paths]
  )

  useEffect(() => {
    document.querySelectorAll('.drawn-path-polyline').forEach((poly) => {
      if (poly instanceof SVGPolylineElement) {
        midMarkersPolyline(poly, 50)
      }
    })
  }, [highlightedPath])

  const handleLocationSelect = useCallback((id: string) => {
    if (!sourceRef.current) {
      setSource(id)
    } else if (!targetRef.current) {
      setTarget(id)
    } else {
      setSource(id)
      setTarget(undefined)
    }
  }, [])

  const handleWCSelect = () => {
    setTarget('wc')
  }

  return (
    <>
      <FloorContainer
        mapImgSrc={selectedFloorMeta.imgSrc}
        mapSVG={selectedFloorMeta.mapSVG}
        mainSvgRef={svgRef}
        drawnPathSVG={`<svg viewBox="${viewBox}">${highlightedPath}</svg>`}
        onOverlayRectClick={(e) => {
          const targetEl = e.currentTarget
          if (!(targetEl instanceof Element)) return

          handleLocationSelect(
            targetEl.getAttribute('id').replace('overlay-', '')
          )
        }}
      />

      <div>
        <Fab
          sx={{
            position: 'fixed',
            right: '2rem',
            bottom: '2rem',
          }}
          color="primary"
          size="large"
          ref={anchorRef}
          onClick={handleOpen}
        >
          {floorMapping[selectedFloor].thumbnail}
        </Fab>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="top-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {Object.values(floorMapping).map((item, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={handleSelect.bind(null, item.code)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                    {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <Fab
        color="secondary"
        sx={{
          position: 'fixed',
          right: '2rem',
          top: '2rem',
        }}
        onClick={handleDrawerOpen}
      >
        <NearMeIcon />
      </Fab>

      <NavDrawer
        onLocationSelect={handleLocationSelect}
        open={drawerOpen}
        onClose={handleDrawerClose}
        source={source}
        target={target}
        onSourceSelect={setSource}
        onTargetSelect={setTarget}
        onWCSelect={handleWCSelect}
        query={query}
      />

      <Box
        sx={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          pointerEvents: drawerOpen ? undefined : 'none',
          opacity: drawerOpen ? 1 : 0,
          zIndex: 10000,
          transition: '0.2s',
        }}
      >
        <Fab onClick={handleDrawerClose} color="default">
          <ArrowRightAltIcon />
        </Fab>
      </Box>
    </>
  )
}
