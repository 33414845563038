import Graph from './graph'

export function findAllPaths(
  source: Graph.Point,
  destination: Graph.Point | ((point: Graph.Point) => boolean)
) {
  //
  let result: Graph.Point[][] = []
  let currentPath: Graph.Point[] = []
  let visited = new Map<Graph.Point, boolean>()

  const isDestination = (point: Graph.Point) =>
    destination instanceof Function ? destination(point) : point === destination

  function dfs(current: Graph.Point) {
    if (visited.get(current)) return

    visited.set(current, true)

    currentPath.push(current)

    if (isDestination(current)) {
      result.push([...currentPath])
    } else {
      current.neighbours.forEach((neighbour) => {
        dfs(neighbour.target)
      })
    }

    visited.set(current, false)
    currentPath.pop()
  }

  dfs(source)

  return result.map((path) => new Graph.Path(path))
}
