import { DRAWN_PATHS_COUNT } from '../../app/indoor-map/IndoorMap'

import stairsUpImg from '../../assets/stairs_up.png'
import stairsDownImg from '../../assets/stairs_down.png'
import startLocationImg from '../../assets/start_location.png'
import endLocationImg from '../../assets/end_location.png'

import Graph from '../graph'
import { arrowMarkerDefs, makePolyline, makeCircleImg } from './utils'

const colorsToPick = [
  '#00B3E6',
  'rgba(90, 120, 130, 0.8)',
  'rgba(152, 152, 152, 0.7)',
]

/**
 * Now floors are number strings
 */
function compareFloors(f1: string, f2: string) {
  return Math.min(1, Math.max(-1, parseInt(f1) - parseInt(f2)))
}

export function highlightPath(
  paths: Graph.Point[][],
  pointsFloor: Map<Graph.Point, string>,
  floor: string | number
) {
  const $paths = document.createElement('g')

  const polylinesHTML = []
  const metaLocationsHTML = []

  paths.forEach((path, idx) => {
    const w = (DRAWN_PATHS_COUNT - idx) * 3 + 4
    const color = colorsToPick[idx]

    let prevPoint: Graph.Point | null = null
    let currentFloor = false
    let polylinePaths: string[][] = [[]]

    path.forEach((point) => {
      if (pointsFloor.get(point) !== floor) {
        currentFloor = false

        if (prevPoint && pointsFloor.get(prevPoint) == floor) {
          const comparison = compareFloors(
            pointsFloor.get(prevPoint),
            pointsFloor.get(point)
          )

          if (comparison !== 0) {
            metaLocationsHTML.push(
              makeCircleImg(
                prevPoint.x,
                prevPoint.y,
                comparison === -1 ? stairsUpImg : stairsDownImg
              )
            )
          }
        }
      } else {
        if (currentFloor) {
          polylinePaths[polylinePaths.length - 1].push(point.x + ',' + point.y)
        } else {
          polylinePaths.push([point.x + ',' + point.y])
        }
        currentFloor = true
      }

      prevPoint = point
    })

    polylinePaths.forEach((pointArr) => {
      polylinesHTML.push(makePolyline(pointArr, color, w))
    })
  })

  const mainPath = paths[0]
  if (mainPath) {
    const firstPoint = mainPath[0]
    const lastPoint = mainPath[mainPath.length - 1]

    if (pointsFloor.get(firstPoint) === floor) {
      metaLocationsHTML.push(
        makeCircleImg(firstPoint.x, firstPoint.y, startLocationImg)
      )
    }
    if (pointsFloor.get(lastPoint) === floor) {
      metaLocationsHTML.push(
        makeCircleImg(lastPoint.x, lastPoint.y, endLocationImg)
      )
    }
  }

  polylinesHTML.reverse()
  $paths.innerHTML += arrowMarkerDefs

  $paths.innerHTML += polylinesHTML.join('') + metaLocationsHTML.join('')

  return $paths.innerHTML
}
