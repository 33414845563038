import Graph from './graph'
import { ParsedXMLResultingGraph } from './graph-from-xml-text'

export function connectGraphsWithPortals(
  graphs: Record<string, ParsedXMLResultingGraph>
) {
  Object.values(graphs).forEach(({ portalMeta }) => {
    portalMeta.forEach((p) => {
      const targetRegexp = /([0-9]+)/
      const targetGraphKey = p.targetId.match(targetRegexp)[1]
      const targetGraph = graphs[targetGraphKey]
      if (!targetGraph) throw new Error('Target graph not found')

      const targetPortalMeta = targetGraph.portalMeta.find(
        (tp) => tp.id === p.targetId
      )
      if (!targetPortalMeta)
        throw new Error('Target point from portal not found')
      const targetPoint = targetPortalMeta.point

      if (!targetPoint.neighbours.some((edge) => edge.target === p.point))
        Graph.Portal.connect(p.point, targetPoint)
    })
  })
}
