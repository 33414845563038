import { getPortalMeta, PortalMeta } from './get-portal-meta'
import Graph from './graph'
import { constructGraphFromNodes } from './graph-from-svg'
import { flatChildNodes } from './utils'

export interface ParsedXMLResultingGraph {
  points: Graph.Point[]
  portalMeta: PortalMeta[]
}

export function graphFromXMLText(text: string): ParsedXMLResultingGraph {
  const parser = new DOMParser()

  const svgDocument = parser.parseFromString(text, 'text/xml')
  const $nav = svgDocument.querySelector('#Navigation')
  const $portals = svgDocument.querySelector('#Stairs')

  if (!$nav || !$portals)
    throw new Error('No `#Navigation` or `#Stairs` element found')
  const nodes = flatChildNodes($nav)
  const portalNodes = flatChildNodes($portals)
  const { points } = constructGraphFromNodes(nodes)
  const portalMeta = getPortalMeta(portalNodes, points)

  return { points, portalMeta }
}
