const forbiddenPairs = [
  ['Portal 1d-2d', 'Portal 1e-2l'],
  ['Portal 2l-1e', 'Portal 2d-1d'],
  ['Portal 2e-3b', 'Portal 2m-3a'],
  ['Portal 3a-2m', 'Portal 3b-2e'],
  ['Portal 1f-2f', 'Portal 1g-2g'],
  ['Portal 2f-1f', 'Portal 2g-1g'],
] as const

export default forbiddenPairs
