import { GetLocationsGroupsQuery } from '../../__generated__/graphql'

export default function parseLocationGroupsQuery(
  query: GetLocationsGroupsQuery
) {
  return {
    groups:
      query.groups?.data.map((group) => ({
        id: group.id,
        label: group.attributes?.label,
        code: group.attributes?.code,
        locations: group.attributes?.locations?.data?.map((location) => ({
          id: location.id,
          label: location.attributes?.label,
          code: location.attributes?.code,
        })),
      })) || [],
    autoCompleteOptions: query.groups?.data.flatMap((group) =>
      group.attributes?.locations?.data?.map((location) => ({
        id: location.id,
        label: location.attributes?.label,
        code: location.attributes?.code,
        group: group.attributes?.label,
      }))
    ),
  }
}
