import styled from '@emotion/styled'
import { Typography } from '@mui/material'

import airplaneCrashSrc from '../assets/airplane.png'

const StyledPageCrashFallback = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const StyledImage = styled.img`
  width: 100%;
  max-width: 620px;
  border-radius: 50%;

  box-shadow: 0 2px 3px #dfdfdf;
  clip-path: inset(3rem 3rem);
`

export default function PageCrashFallback() {
  return (
    <StyledPageCrashFallback>
      <StyledImage src={airplaneCrashSrc} alt="Plane crash" />
      <Typography variant="h5" align="center" fontWeight={500}>
        Oops, ceva nu a mers bine, contactați administrația!
      </Typography>
      <Typography
        component="a"
        fontSize="0.75rem"
        align="center"
        href="https://www.flaticon.com/free-icons/crash"
        title="crash icons"
      >
        Crash icons created by pongsakornRed - Flaticon
      </Typography>
    </StyledPageCrashFallback>
  )
}
